import React, { useContext, useEffect } from "react";
import { navigate } from "gatsby";

import {
    CurrentUserContext,
    ATTR_ACCEPTED_TERMS_TIME,
} from "../providers/auth";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Loading from "../components/loading";

export default function Index() {
    return (
        <Layout>
            <Seo title="" />
            <IndexPage />
        </Layout>
    );
}

const IndexPage = () => {
    const currentUser = useContext(CurrentUserContext);

    useEffect(() => {
        const t = setTimeout(() => {
            currentUser
                .currentAuthenticatedUser()
                .then((user) => {
                    const afterLogin = window.sessionStorage.getItem(
                        "redirectAfterLogin"
                    );
                    if (
                        !(
                            user.attributes &&
                            !!user.attributes[ATTR_ACCEPTED_TERMS_TIME]
                        )
                    ) {
                        navigate(`/account/?redirect=true`);
                    } else if (afterLogin) {
                        const url = new URL(afterLogin);
                        navigate(url.pathname);
                    } else {
                        navigate("/meshspec");
                    }
                })
                .catch(() => {
                    navigate("/meshspec/new");
                });
        }, 1000);

        return () => {
            clearTimeout(t);
        };
    }, [currentUser]);

    return <Loading />;
};
